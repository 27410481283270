import React, { useEffect } from "react";

const HubspotContactForm = (props) => {
    const { region, portalId, formId, target } = props;
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "//js.hsforms.net/forms/embed/v2.js";;
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    region: region,
                    portalId: portalId,
                    formId: formId,
                    target: '#' + target,
                    onFormSubmit: function () {
                        localStorage.setItem("downloadable", true);
                    }
                })
            }
        });
    }, []);

    return (
        <div>
            <div id={target}></div>
        </div>
    );
};

export default HubspotContactForm;