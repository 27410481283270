import React, { useEffect, useState } from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import ReactHtmlParser from 'react-html-parser';
import { Leadership, OurStory, careerFeature, specialists } from '../data/Miscellaneous';
import { injectIntl } from 'react-intl';
import { isInViewport, scrollShow } from '../utils/RenderIfVisible';

const AboutCompany = injectIntl((props) => {
    const { intl, } = props;
    const [resize, setResize] = useState(false);
    const [inView, setInView] = useState(false);
    const [showMap, setShowMap] = useState({
        "aboutWho": false,
        "aboutMission": false,
        "aboutValues": false
    });
    const array = Array.from(Array(11).keys());
    const values = Array.from(Array(4).keys());

    useEffect(() => {
        function recordWindowSize() {
            if (window.innerWidth < 1000) {
                setResize(true);
            } else {
                setResize(false);
            };
        };
        recordWindowSize();
        window.addEventListener('resize', recordWindowSize);
        window.addEventListener('scroll', () => setInView(isInViewport("timeline1")));
        window.addEventListener('scroll', () => scrollShow(showMap, setShowMap));
    }, []);

    const TimelineCard = (props) => {
        const { date, event, direction, top, dummy } = props;
        if (dummy) {
            return (
                <div className={`about-timeline-item-${direction} bounce-in-${direction}`} style={{ marginTop: top }} />
            );
        };
        return (
            <div className={`about-timeline-item-${direction} bounce-in-${direction}`} style={{ marginTop: top }}>
                <div className='mont-semi-bold-white-23px' style={{ color: "var(--pizazz)" }}>{intl.formatMessage({ id: date })}</div>
                <div className='mont-semi-bold-white-23px'>{ReactHtmlParser(intl.formatMessage({ id: event }))}</div>
                <img src="/images/resource-industry-divider.png" style={{ height: 2 }} />
            </div>
        );
    };

    return (
        <div className='siteContent'>
            <Header pageName="About-us" option="company" />
            <div className='about-company-title mont-bold-white-80px'>
                <h1 className="fade-in" style={{ fontSize: 80 }}>{intl.formatMessage({ id: "about.title" })}</h1>
            </div>
            <div className='about-company-content'>
                {!showMap["aboutWho"] && <div id="aboutWho" className='decoy' />}
                {showMap["aboutWho"] && <div className='fade-in'>
                    <div className='mont-semi-bold-white-45px'>{intl.formatMessage({ id: "about.who.title" })}</div>
                    <img src="/images/technology_divider.png" className='image-center' style={{ marginTop: 10, marginBottom: 30, width: 250 }} />
                    <div className="mont-regular-normal-white-20px margin-center fade-in-up-big" style={{ maxWidth: 1200 }}>
                        {ReactHtmlParser(intl.formatMessage({ id: "about.who.content" }))}
                    </div>
                </div>}
                {!showMap["aboutMission"] && <div id="aboutMission" className='decoy' />}
                {showMap["aboutMission"] && <div className='fade-in'>
                    <div className='mont-semi-bold-white-45px' style={{ marginTop: 50 }}>{intl.formatMessage({ id: "about.mission.title" })}</div>
                    <img src="/images/technology_divider.png" className='image-center' style={{ marginTop: 10, marginBottom: 30, width: 250 }} />
                    <div className="mont-regular-normal-white-30px margin-center fade-in-up-big" style={{ maxWidth: 1200 }}>
                        {ReactHtmlParser(intl.formatMessage({ id: "about.mission.content" }))}
                    </div>
                </div>}
                {!showMap["aboutValues"] && <div id="aboutValues" className='decoy' />}
                {showMap["aboutValues"] && <div style={{ background: "var(--bgBlue)", marginTop: 40, padding: "30px 20px" }} className="fade-in">
                    <div className='mont-semi-bold-white-45px'>{intl.formatMessage({ id: "about.values.title" })}</div>
                    <img src="/images/technology_divider.png" className='image-center' style={{ marginTop: 10, marginBottom: 30, width: 250 }} />
                    <p className="mont-regular-normal-white-20px">{intl.formatMessage({ id: "about.values.content" })}</p>
                    <div className='about-company-culture-flex bounce-in'>
                        {
                            values.map(index =>
                                <div className='about-company-culture-item'>
                                    <img src={`/images/about-val-${index + 1}.png`} style={{ height: 120 }} className="image-center" />
                                    <div className="mont-semi-bold-white-25px" style={{ marginTop: 20 }}>{intl.formatMessage({ id: `about.values${index + 1}` })}</div>
                                </div>
                            )
                        }
                    </div>
                </div>}
            </div>
            <div className='about-company-timeline'>
                <div className='mont-semi-bold-white-45px bounce' style={{ marginTop: 50, textAlign: "center" }}>Milestones</div>
                <img src="/images/technology_divider.png" className='image-center' style={{ marginTop: 10, marginBottom: 30, width: 250 }} />
                <div id="timeline1" />
                {inView && <div className='about-timeline-flex' >
                    <img src="/images/about-timeline-divider.png" className='absolute-center' style={{ height: "100%", left: resize ? "80%" : "50%" }} />
                    {
                        array.map(index => index % 2 === 0 ?
                            <TimelineCard date={"about.date" + (index + 1)} event={"about.event" + (index + 1)} direction="left" />
                            :
                            <TimelineCard date={"about.date" + (index + 1)} event={"about.event" + (index + 1)} direction={resize ? "left" : "right"} top={resize ? 0 : 80} />)
                    }
                    <TimelineCard date="" event="" direction="right" top={80} dummy />
                </div>}
            </div>
            <Footer />
        </div>
    );
});

const TeamGallery = injectIntl((props) => {
    const { intl, gap, data } = props;
    const [expand, setExpand] = useState("");

    return (
        <div className='about-team-flex' style={{ columnGap: gap }}>
            {
                data.map(item =>
                    <div className='about-team-item' onMouseEnter={() => setExpand(item.name)} onMouseLeave={() => setExpand("")}>
                        {expand !== item.name &&
                            <div className='about-team-avatar image-center fade-in'
                                style={{ backgroundImage: `url(${item.avatar})`, backgroundSize: item.size }} />}
                        {expand !== item.name &&
                            <div className='fade-in'>
                                <div className="mont-semi-bold-white-25px">{item.name}</div>
                                <div className="mont-regular-normal-white-18px" style={{ lineHeight: 1.3 }}>{intl.formatMessage({ id: item.title })}</div>
                            </div>}
                        {expand === item.name &&
                            <div className='mont-regular-normal-white-18px fade-in about-team-intro'>
                                <p>{item.name}<br />{intl.formatMessage({ id: item.title })}</p>
                                <p style={{ fontSize: 15 }}>{ReactHtmlParser(intl.formatMessage({ id: item.intro }))}</p>
                            </div>}
                        <a href={item.link} className="align-right about-linkedin" target="_blank">
                            <img src="/images/linkedin.png" width={30} />
                        </a>
                    </div>
                )
            }
        </div>
    );
});

const AboutTeam = injectIntl((props) => {
    const { intl } = props;
    const [showMap, setShowMap] = useState({
        "aboutEx": false,
        "aboutSp": false,
        "aboutStats": false,
        "aboutHl": false,
    });

    useEffect(() => {
        window.addEventListener('scroll', () => scrollShow(showMap, setShowMap));
    }, []);

    return (
        <div className='siteContent'>
            <Header pageName="About-us" option="team" />
            <div className='about-company-title mont-bold-white-80px' style={{ backgroundImage: "url(/images/about-team-bg1.png" }}>
                <h1 className='fade-in' style={{ fontSize: 80 }}>{intl.formatMessage({ id: "header.about.team" })}</h1>
                <div className="mont-regular-normal-white-30px fade-in" style={{ marginTop: 20 }}>{intl.formatMessage({ id: "about.team.value" })}</div>
            </div>
            <div className='about-company-content common-wrapper'>
                {!showMap["aboutEx"] && <div className='decoy' id="aboutEx" />}
                {showMap["aboutEx"] && <div className='fade-in'>
                    <div className='mont-semi-bold-white-45px'>{intl.formatMessage({ id: "about.team.executive" })}</div>
                    <img src="/images/technology_divider.png" className='image-center' style={{ marginTop: 10, marginBottom: 100, width: 300 }} />
                    <TeamGallery gap={30} data={Leadership} />
                </div>}
                {!showMap["aboutSp"] && <div className='decoy' id="aboutSp" />}
                {showMap["aboutSp"] && <div className='fade-in'>
                    <div className='mont-semi-bold-white-45px'>{intl.formatMessage({ id: "about.team.specialist" })}</div>
                    <img src="/images/technology_divider.png" className='image-center' style={{ marginTop: 10, marginBottom: 100, width: 300 }} />
                    <TeamGallery gap={80} data={specialists} />
                </div>}
                {!showMap["aboutStats"] && <div className='decoy' id="aboutStats" />}
                {showMap["aboutStats"] && <div className='about-team-stats bounce-in'>
                    <div className='about-team-stats-item'>
                        <div className='about-team-transition' />
                        <div style={{ position: "absolute", marginTop: 50 }}>
                            <div className="mont-bold-denim-14px" style={{ fontSize: 100 }}>&gt;45%</div>
                            <div className="mont-bold-white-38px">{ReactHtmlParser(intl.formatMessage({ id: "about.team.stats1" }))}</div>
                        </div>
                    </div>
                    <div className='about-team-stats-item'>
                        <div className='about-team-transition' />
                        <div style={{ position: "absolute", marginTop: 50 }}>
                            <div className="mont-bold-denim-14px" style={{ fontSize: 100 }}>&gt;80%</div>
                            <div className="mont-bold-white-38px">{ReactHtmlParser(intl.formatMessage({ id: "about.team.stats2" }))}</div>
                        </div>
                    </div>
                </div>}
                {!showMap["aboutHl"] && <div className='decoy' id="aboutHl" />}
                {showMap["aboutHl"] && <div className='fade-in-up-big'>
                    <div className='mont-semi-bold-white-45px'>{intl.formatMessage({ id: "about.team.highlight" })}</div>
                    <img src="/images/technology_divider.png" className='image-center' style={{ marginTop: 10, marginBottom: 30, width: 300 }} />
                    <div className='row-wrap' style={{ marginBottom: 50, gap: "60px 40px" }}>
                        {OurStory.map((link, index) =>
                            <a href={link} key={"hl" + index + 1} target="_blank">
                                <img src={`/images/about-hl-${index + 1}-min.png`} loading="lazy" width={400} className="about-hl" />
                            </a>
                        )}
                    </div>
                </div>}
            </div>
            <Footer />
        </div>
    );
});

const AboutCareer = injectIntl((props) => {
    const { intl } = props;
    const [showMap, setShowMap] = useState({
        "aboutJoin": false,
        "aboutOffer": false,
        "aboutPos": false,
    });

    useEffect(() => {
        window.addEventListener('scroll', () => scrollShow(showMap, setShowMap));
    }, []);

    return (
        <div className='siteContent'>
            <Header pageName="About-us" option="career" />
            <div className='about-company-title mont-bold-white-80px' style={{ backgroundImage: "url(/images/about-career-bg.png" }}>
                <h1 className='fade-in' style={{ fontSize: 80 }}>{intl.formatMessage({ id: "header.about.career" })}</h1>
            </div>
            <div className='about-company-content common-wrapper'>
                {!showMap["aboutJoin"] && <div id="aboutJoin" className='decoy' />}
                {showMap["aboutJoin"] && <div className='fade-in-up-big'>
                    <div className='mont-semi-bold-white-45px'>
                        {intl.formatMessage({ id: "about.career.join" })}
                    </div>
                    <img src="/images/technology_divider.png" className='image-center about-divider' width={400} />
                    <p className='mont-regular-normal-white-25px margin-center' style={{ maxWidth: 1100, marginBottom: 50 }}>
                        {ReactHtmlParser(intl.formatMessage({ id: "about.career.join.content" }))}
                    </p>
                </div>}
                {!showMap["aboutOffer"] && <div id="aboutOffer" className='decoy' />}
                {showMap["aboutOffer"] && <div className='fade-in-up-big'>
                    <div className='mont-semi-bold-white-45px'>{intl.formatMessage({ id: "about.career.offer" })}</div>
                    <img src="/images/technology_divider.png" className='image-center about-divider' width={400} />
                    <div className='about-career-flex bounce-in'>
                        {
                            careerFeature.map((item, index) =>
                                <div className='about-career-item' key={index}>
                                    <img src={item.imgSrc} className='image-center' style={{ marginBottom: 40, width: 80 }} />
                                    <div className='mont-semi-bold-white-25px'>{ReactHtmlParser(intl.formatMessage({ id: item.content }))}</div>
                                </div>
                            )
                        }
                    </div>
                </div>}
                {!showMap["aboutPos"] && <div id="aboutPos" className='decoy' />}
                {showMap["aboutPos"] && <div className='fade-in-up-big'>
                    <div className='mont-semi-bold-white-45px'>{intl.formatMessage({ id: "about.career.position" })}</div>
                    <img src="/images/technology_divider.png" className='image-center about-divider' style={{ height: 6 }} />
                    <div className='margin-center' style={{ maxWidth: 1200 }}>
                        <div className='about-career-position mont-regular-normal-white-30px'>
                            <a href="https://ca.indeed.com/job/senior-software-engineer-80e18298577446c3" target="_blank">
                                {intl.formatMessage({ id: "about.career.position1" })}
                            </a>
                            <div>Burnaby, BC, Canada</div>
                        </div>
                        <img src="/images/about-career-divider.png" />
                        <div className='about-career-position mont-regular-normal-white-30px' target="_blank">
                            <a href="https://ca.indeed.com/job/marketing-assistant-b843e77d31e02929">
                                {intl.formatMessage({ id: "about.career.position2" })}
                            </a>
                            <div>Burnaby, BC, Canada</div>
                        </div>
                        <img src="/images/about-career-divider.png" />
                        <div className='about-career-position mont-regular-normal-white-30px' target="_blank">
                            <a href="https://ca.indeed.com/job/executive-assistant-5af4d8a8e5f4577e">
                                {intl.formatMessage({ id: "about.career.position3" })}
                            </a>
                            <div>Burnaby, BC, Canada</div>
                        </div>
                        <img src="/images/about-career-divider.png" />
                        <p className='mont-regular-normal-white-25px' style={{ textAlign: "start", marginTop: 40, color: "var(--pizazz)" }}>
                            {intl.formatMessage({ id: "about.career.moreinfo" })}
                        </p>
                    </div>
                </div>}
            </div>
            <Footer />
        </div>
    );
});

export { AboutCareer, AboutCompany, AboutTeam };