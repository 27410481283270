import React, { useRef, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import ReactGA from 'react-ga';

// Views
import Home from './views/Home';
import { ProductOverview, ProductBlushield, ProductBlusensor, ProductBlucam, ProductBlugun, ProductSoftware, ProductBlucase } from "./views/Products";
import { SolutionOverview, SolutionAirport, SolutionCommercial, SolutionInfrastructure, SolutionPublic, SolutionMilitary, SolutionCorrectional } from './views/Solution';
import { TechnologyOverview, TechDSI, TechTDOA, TechRTI } from './views/Technology';
import { ResourcePage } from './views/Resource';
import { PartnerOverview, PartnerLogin } from './views/Partner';
import { AboutCareer, AboutCompany, AboutTeam } from './views/About';
import Contact from './views/Contact';
import ScrollToTop from "./utils/ScrollToTop";
import Article from "./views/Article"
import { LoadFile, DocCreator } from "./views/DataSheet.js";
import { PrivacyPolicy } from "./views/PrivacyPolicy";


// Initialize Google Analytics
ReactGA.initialize("UA-232217511-1");

const trackPage = page => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const App = () => {

  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add('is-loaded')
    childRef.current.init();
    trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (

    <ScrollReveal
      ref={childRef}
      children={() => (
        <ScrollToTop>
          {/*<ApolloProvider client = {client}>*/}
          <Switch>
            <AppRoute exact path="/" component={Home} />
            <AppRoute exact path="/Products/Overview" component={ProductOverview} />
            {/* <AppRoute exact path="/Products/Blushield" component={ProductBlushield} /> */}
            <AppRoute exact path="/Products/Blusensor" component={ProductBlusensor} />
            <AppRoute exact path="/Products/Blucam" component={ProductBlucam} />
            {/* <AppRoute exact path="/Products/Blugun" component={ProductBlugun} /> */}
            <AppRoute exact path="/Products/Blucase" component={ProductBlucase} />
            <AppRoute exact path="/Products/Software" component={ProductSoftware} />
            <AppRoute exact path="/Solutions/Overview" component={SolutionOverview} />
            <AppRoute exact path="/Solutions/Airports" component={SolutionAirport} />
            <AppRoute exact path="/Solutions/Infrastructure" component={SolutionInfrastructure} />
            <AppRoute exact path="/Solutions/Correctional" component={SolutionCorrectional} />
            <AppRoute exact path="/Solutions/Public" component={SolutionPublic} />
            <AppRoute exact path="/Solutions/Military" component={SolutionMilitary} />
            <AppRoute exact path="/Solutions/Commercial" component={SolutionCommercial} />
            <AppRoute exact path="/Technology/Overview" component={TechnologyOverview} />
            <AppRoute exact path="/Technology/DSI" component={TechDSI} />
            <AppRoute exact path="/Technology/TDOA" component={TechTDOA} />
            <AppRoute exact path="/Technology/RTI" component={TechRTI} />
            <AppRoute exact path="/Resources" component={ResourcePage} />
            <AppRoute exact path="/Resources/Article/:title" component={Article} />
            <AppRoute exact path="/Partner" component={PartnerOverview} />
            <AppRoute exact path="/About-us/Company" component={AboutCompany} />
            <AppRoute exact path="/About-us/Team" component={AboutTeam} />
            <AppRoute exact path="/About-us/Career" component={AboutCareer} />
            <AppRoute exact path="/Contact" component={Contact} />
            <AppRoute exact path="/Policy-and-terms" component={PrivacyPolicy} />
            <AppRoute exact path="/Aboutus" component={AboutCompany} />
            <AppRoute exact path="/Press" component={ResourcePage} />
            <AppRoute exact path="/Products" component={ProductOverview} />
            <AppRoute exact path="/Doc/:docName" component={DocCreator} />
            <AppRoute exact path="/:fileName" component={LoadFile} />
            <AppRoute path="/" component={Home} />
          </Switch>
          {/*</ApolloProvider>*/}
        </ScrollToTop>
      )} />
  );
}

export default App;
