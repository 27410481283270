import React, { useState, useEffect } from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import ReactHtmlParser from 'react-html-parser';
import { BasicInfo } from './Products';
import { injectIntl } from 'react-intl';
import { isInViewport, scrollShow } from '../utils/RenderIfVisible';
import { FeatureBlock } from './Products';
import { isMobile } from '../utils/MobileAdapt';

const OverviewBlock = injectIntl((props) => {
    const { bgSrc, bgSize, imgSrc, imgTop, imgLeft, text1, text2, textLeft, textTop, link, intl, iltr, imgWidth } = props;
    return (
        <div className='technology-overview-dsi fade-in' style={{ backgroundImage: `url(/images/${bgSrc})`, backgroundSize: bgSize }}>
            {iltr && <img src={`/images/${imgSrc}`} style={{ marginTop: imgTop, marginLeft: imgLeft, width: imgWidth }} className="fade-in-left" />}
            <div className="technology-overview-text" style={{ marginLeft: textLeft, marginTop: textTop }}>
                <div className='technology-overview-text-block bounce-in'>
                    <div className="rectangle rectangle-p1" />
                    <div className="rectangle rectangle-p2" />
                    <div className="rectangle rectangle-p3" />
                    <div className="rectangle rectangle-p4" />
                    <div className="mont-bold-white-48px">{intl.formatMessage({ id: text1 })}</div>
                    <div className="inter-normal-white-20px" style={{ fontSize: isMobile() ? 20 : 25, marginTop: 20, marginBottom: 50 }}>
                        {intl.formatMessage({ id: text2 })}
                    </div>
                    <a href={link}><button className="blusensor-learnMore">
                        {intl.formatMessage({ id: "general.learnMore" })}
                    </button></a>
                </div>
            </div>
            {!iltr && <img src={`/images/${imgSrc}`} style={{ marginTop: imgTop, marginLeft: imgLeft, width: imgWidth }} className="fade-in-right" />}
        </div>
    );
});

const DetailBlock = injectIntl((props) => {
    const { bgSrc, bgSize, imgSrc, imgWidth, imgLeft, text, title, textLeft, textTop, intl, imgTop } = props;
    return (
        <div className='technology-overview-dsi row-wrap fade-in'
            style={{ backgroundImage: `url(/images/${bgSrc})`, backgroundSize: bgSize, padding: "80px 20px" }}>
            <div className="mont-bold-white-48px" style={{ width: "100%", textAlign: "center" }}>
                {intl.formatMessage({ id: title })}
            </div>
            <img src={`/images/${imgSrc}`} style={{ marginLeft: imgLeft, width: imgWidth, marginTop: imgTop }} className="fade-in-up-big" />
            <div style={{ marginLeft: textLeft, marginTop: textTop, width: 800 }}>
                <div className='partner-card tech-detail-text'>
                    <div className="inter-normal-white-20px">
                        {ReactHtmlParser(intl.formatMessage({ id: text }))}
                    </div>
                </div>
            </div>
        </div>
    );
});

const OverviewFlow = (props) => {
    const { index, text1, text2, textLeft, textRight, color, bgColor, textWidth, rArrow, lArrow } = props;
    const textPart = (
        <div className="mont-semi-bold-white-25px" style={{ width: isMobile() ? 150 : 350, marginLeft: textLeft, marginRight: textRight }}>
            {ReactHtmlParser(text2)}
        </div>
    );

    const [shine, setShine] = useState(false);

    return (
        <div className="tech-workflow-flex bounce-in">
            {rArrow && !isMobile() && <img src="/images/tech-workflow-arrow.png" style={{ position: "absolute", width: 150, top: 116, right: 310 }} />}
            {lArrow && !isMobile() && <img src="/images/tech-workflow-arrow2.png" style={{ position: "absolute", width: 150, top: 116, right: 600 }} />}
            {index % 2 === 0 && textPart}
            {!shine && <div className='technology-circle tech-c1' onMouseEnter={() => setShine(true)}
                style={{ borderColor: `var(--${color}1)`, position: "relative" }}>
                <div className='technology-circle tech-c2 absolute-center' style={{ borderColor: `var(--${color}2)` }}>
                    <div className='technology-circle tech-c3 absolute-center' style={{ borderColor: `var(--${color})` }}>
                        <button className='technology-circle tech-c4 mont-bold-white-48px absolute-center'
                            style={{ borderColor: `var(--${color})`, backgroundColor: `var(--${color})` }}>
                            {index}
                        </button>
                    </div>
                </div>
            </div>}
            {shine && <div className='tech-c1' style={{ position: "relative", borderColor: `var(--${color})`, cursor: "pointer" }}
                onMouseLeave={() => setShine(false)}>
                <div className='water1' />
                <div className='water2' />
                <div className='water3' />
                <div className='water4' />
                <button className='technology-circle tech-c4 mont-bold-white-48px absolute-center'
                    style={{ borderColor: `var(--${color})`, backgroundColor: `var(--${color})` }}>
                    {index}
                </button>
            </div>}
            <div className="tech-flow-item1">
                <div className="tech-flow-item2 mont-bold-white-20px" style={{ backgroundColor: `var(--${bgColor})`, width: textWidth ? textWidth : "auto" }}>
                    {ReactHtmlParser(text1)}
                </div>
            </div>
            {index % 2 === 1 && textPart}
        </div>
    )
}

const TechnologyOverview = injectIntl((props) => {
    const { intl } = props;
    const [showMap, setShowMap] = useState({
        "techIntro": false,
        "techDSI": false,
        "techRTI": false,
        "techTDOA": false,
        "techFlow1": false,
        "techFlow2": false,
    });

    useEffect(() => {
        window.addEventListener('scroll', () => scrollShow(showMap, setShowMap));
    }, []);

    return (
        <div className='siteContent'>
            <Header pageName="Technology" option="technologyOverview" />

            <BasicInfo
                text1=""
                text2="Technology"
                text3=" "
                bgSrc="technology_overview_background.png" imgSrc="blusensor-1.png"
                textTop={100} bgHeight={500} imgWidth={0} />

            {!showMap["techIntro"] && <div className='decoy' id="techIntro" />}
            {showMap["techIntro"] && <div className='technology-overview fade-in-up-big'>
                <p className='mont-bold-white-48px' style={{ marginBottom: 20 }}>
                    {intl.formatMessage({ id: "tech.title" })}
                </p>
                <img src="/images/technology_overview_divider.svg" style={{ marginLeft: "auto", marginRight: "auto", marginBottom: 50 }} />
                <p className='mont-semi-bold-white-45px margin-center' style={{ lineHeight: 1.4, fontSize: 36, width: 1000, maxWidth: "80vw" }}>
                    {intl.formatMessage({ id: "tech.overview.intro" })}
                </p>
            </div>}

            {!showMap["techDSI"] && <div className='decoy' id="techDSI" />}
            {showMap["techDSI"] && <OverviewBlock
                bgSrc="technology_overview_bg1.png"
                imgSrc="blusensor-2-blue.png" imgTop={100} textTop={50} iltr={true} imgWidth={600}
                link="/technology/DSI"
                text1="header.technology.DSI" text2="tech.overview.dsi"
            />}

            {!showMap["techRTI"] && <div className='decoy' id="techRTI" />}
            {showMap["techRTI"] && <OverviewBlock
                bgSrc="technology_overview_bg2.png"
                imgSrc="blucam-1.png" imgTop={0} textTop={50} iltr={false} imgWidth={400}
                link="/technology/RTI"
                text1="header.technology.RTI" text2="tech.overview.rti"
            />}

            {!showMap["techTDOA"] && <div className='decoy' id="techTDOA" />}
            {showMap["techTDOA"] && <OverviewBlock
                bgSrc="technology_overview_bg3.png"
                imgSrc="tech_tdoa.png" imgTop={100} textTop={50} iltr={true} imgWidth={500}
                link="/technology/TDOA"
                text1="TDOA" text2="tech.overview.tdoa"
            />}

            {!showMap["techFlow1"] && <div className='decoy' id="techFlow1" />}
            {showMap["techFlow1"] && !isMobile() && <div className='technology-workflow fade-in'>
                <div className="mont-semi-bold-denim-48px" style={{ marginBottom: 30, textAlign: "center" }}>
                    {intl.formatMessage({ id: "tech.vision.title" })}
                </div>
                <img src="/images/technology_divider2.svg" style={{ marginRight: "auto", marginLeft: "auto" }} />
                <OverviewFlow
                    index={1} textLeft={100} color="indigo" bgColor="denim" textWidth={isMobile() ? 100 : 300} rArrow
                    text1={intl.formatMessage({ id: "tech.vision.wf1" })} text2={intl.formatMessage({ id: "tech.vision.wf1.op" })} />
                <OverviewFlow
                    index={2} textLeft={0} textRight={50} color="indigo" bgColor="denim" lArrow
                    text1={intl.formatMessage({ id: "tech.vision.wf2" })} text2={intl.formatMessage({ id: "tech.vision.wf2.op" })} />
                <OverviewFlow
                    index={3} textLeft={100} color="indigo" bgColor="denim" rArrow
                    text1={intl.formatMessage({ id: "tech.vision.wf3" })} text2={intl.formatMessage({ id: "tech.vision.wf3.op" })} />
                <OverviewFlow
                    index={4} textLeft={0} textRight={50} color="pizazz" bgColor="pizazz" textWidth={isMobile() ? 100 : 250}
                    text1={intl.formatMessage({ id: "tech.vision.wf4" })} />
                <OverviewFlow
                    index={5} textLeft={100} color="pizazz" bgColor="pizazz" textWidth={isMobile() ? 100 : 270} rArrow
                    text1={intl.formatMessage({ id: "tech.vision.wf5" })} text2={intl.formatMessage({ id: "tech.vision.wf5.op" })} />
                <OverviewFlow
                    index={6} textLeft={0} textRight={50} color="red" bgColor="red" textWidth={isMobile() ? 100 : 230}
                    text1={intl.formatMessage({ id: "tech.vision.wf6" })} />
            </div>}
            {showMap["techFlow1"] && isMobile() && <div className='technology-workflow fade-in'>
                <div className="mont-semi-bold-denim-48px" style={{ marginBottom: 30, textAlign: "center" }}>
                    {intl.formatMessage({ id: "tech.vision.title" })}
                </div>
                <img src="/images/workflow-vision.png" style={{ maxWidth: "90vw" }} />
            </div>}

            {!showMap["techFlow2"] && <div className='decoy' id="techFlow2" />}
            {showMap["techFlow2"] && <div className='technology-workflow fade-in'>
                <div className="mont-semi-bold-denim-48px" style={{ marginBottom: 30, textAlign: "center" }}>
                    {intl.formatMessage({ id: "tech.wireless.title" })}
                </div>
                <img src="/images/technology_divider2.svg" style={{ marginRight: "auto", marginLeft: "auto", marginBottom: 50 }} />
                <img src="/images/WirelessGraph.svg" className='margin-center' width={1000} />
            </div>}
            <Footer />
        </div>
    )
});

const TechRelevant = injectIntl((props) => {
    const { bgSrc, blusensor, blushield, blugun, blusensorLV, blucam, intl } = props;

    const ProductCard = (props) => {
        const { width, imgSrc, title, top, bottom, link } = props;
        return (
            <div className='tech-relevant-item'>
                <img src={`/images/${imgSrc}`} width={width} className="image-center" style={{ marginTop: top, marginBottom: bottom }} />
                <div className='mont-bold-white-38px tech-relevant-text'>{intl.formatMessage({ id: title })}</div>
                <div style={{ marginTop: -10 }}><a href={link}><button className="blusensor-learnMore">{intl.formatMessage({ id: "general.learnMore" })}</button></a></div>
            </div>
        );
    };
    return (
        <div className='tech-relevant fade-in' style={{ backgroundImage: `url(/images/${bgSrc})` }}>
            <div className='common-wrapper' style={{ width: 1300 }}>
                <div id="relevant" style={{ position: "relative", top: 500 }} />
                <div className='mont-bold-white-48px'>{intl.formatMessage({ id: "tech.relevant" })}</div>
                <img src="/images/technology_divider.png" style={{ marginTop: 40, marginBottom: 40, height: 6 }} />
                <div className='tech-relevant-flex fade-in-up'>
                    {blusensor && <ProductCard width={260} imgSrc="blusensor-rect-blue.png" title="tech.relevant.blusensor" link="/Products/Blusensor" />}
                    {/* {blushield && <ProductCard width={215} imgSrc="blushield-ad8000-2.png" title="tech.relevant.blushield" link="/Products/Blushield" />}
                    {blugun && <ProductCard width={300} imgSrc="blugun.png" title="tech.relevant.blugun" link="/Products/Blugun" top={107} bottom={100} />} */}
                    {blusensorLV && <ProductCard width={200} imgSrc="blusensor-lv-RE.png" title="tech.relevant.blusensorLV" link="/Products/Blusensor" top={-20} />}
                    {blucam && <ProductCard width={200} imgSrc="blucam.png" title="tech.relevant.blucam" link="/Products/Blucam" />}
                </div>
            </div>
        </div>
    );
});

const TechDSI = injectIntl((props) => {
    const { intl } = props;

    const [showMap, setShowMap] = useState({
        "intro": false,
        "detail": false,
        "feature": false,
        "relevant": false,
    });

    useEffect(() => {
        window.addEventListener('scroll', () => scrollShow(showMap, setShowMap));
    }, []);

    return (
        <div className='siteContent'>
            <Header pageName="Technology" option="DSI" />
            <BasicInfo
                text1=""
                text2="tech.dsi"
                text3=" "
                bgSrc="technology_dsi.png"
                textTop={100} bgHeight={500} imgWidth={300} imgHeight={330} textWidth={1000} />

            {!showMap["intro"] && <div className='decoy' id="intro" />}
            {showMap["intro"] && <div className='technology-overview fade-in'>
                <div className='mont-bold-white-48px' style={{ marginBottom: 20 }}>
                    {intl.formatMessage({ id: "tech.dsi.title" })}
                </div>
                <img src="/images/technology_overview_divider.svg" style={{ marginLeft: "auto", marginRight: "auto" }} />
                <div className="inter-normal-white-20px" style={{ padding: "50px 20px", lineHeight: 1.8, textAlign: "left" }}>
                    {ReactHtmlParser(intl.formatMessage({ id: "tech.dsi.intro" }))}
                </div>
            </div>}

            {!showMap["detail"] && <div className='decoy' id="detail" />}
            {showMap["detail"] && <DetailBlock
                imgSrc="blusensor-2-blue.png" imgWidth={500}
                bgSrc="technology_bg.png"
                text="tech.dsi.detail"
                title="tech.dsi.detail.title"
                textTop={100}
            />}

            {!showMap["feature"] && <div className='decoy' id="feature" />}
            {showMap["feature"] && <FeatureBlock featureData={[0, 0, 0, 0, 0]} bgSrc="/images/earth-purple.png" intlKey="dsi" prefix="tech" />}

            {!showMap["relevant"] && <div className='decoy' id="relevant" />}
            {showMap["relevant"] && <TechRelevant bgSrc="technology_relevant_bg1.png" blusensor={true} blushield={false} blugun={true} />}

            <Footer />
        </div>
    );
});

const TechTDOA = injectIntl((props) => {
    const { intl } = props;

    const [showMap, setShowMap] = useState({
        "intro": false,
        "detail": false,
        "feature": false,
        "relevant": false,
    });

    useEffect(() => {
        window.addEventListener('scroll', () => scrollShow(showMap, setShowMap));
    }, []);

    return (
        <div className='siteContent'>
            <Header pageName="Technology" option="TDOA" />
            <BasicInfo
                text1=""
                text2="tech.tdoa"
                text3=" "
                bgSrc="technology_TDOA.png"
                textTop={100} bgHeight={500} imgWidth={300} imgHeight={330} textWidth={1000} />

            {!showMap["intro"] && <div className='decoy' id="intro" />}
            {showMap["intro"] && <div className='technology-overview fade-in'>
                <div className='mont-bold-white-48px' style={{ marginBottom: 20 }}>
                    {ReactHtmlParser(intl.formatMessage({ id: "tech.tdoa.title" }))}
                </div>
                <img src="/images/technology_overview_divider.svg" style={{ marginLeft: "auto", marginRight: "auto" }} />
                <div className="inter-normal-white-20px" style={{ padding: "50px 20px", lineHeight: 1.8, textAlign: "left" }}>
                    {ReactHtmlParser(intl.formatMessage({ id: "tech.tdoa.intro" }))}
                </div>
            </div>}

            {!showMap["detail"] && <div className='decoy' id="detail" />}
            {showMap["detail"] && <DetailBlock
                imgSrc="tech_tdoa.png"
                bgSrc="technology_bg.png"
                text="tech.tdoa.detail"
                title="tech.tdoa.detail.title"
                textTop={50} imgWidth={450} imgTop={100}
            />}

            {!showMap["feature"] && <div className='decoy' id="feature" />}
            {showMap["feature"] && <FeatureBlock featureData={[50, 0, 0, 0, 0]} bgSrc="/images/earth-blue.png" intlKey="tdoa" prefix="tech" />}

            {!showMap["relevant"] && <div className='decoy' id="relevant" />}
            {showMap["relevant"] && <TechRelevant bgSrc="technology_relevant_bg2.png" blusensor={true} blushield={false} blusensorLV={true} />}

            <Footer />
        </div>
    );
});

const TechRTI = injectIntl((props) => {
    const { intl } = props;

    const [showMap, setShowMap] = useState({
        "intro": false,
        "detail": false,
        "feature": false,
        "relevant": false,
    });

    useEffect(() => {
        window.addEventListener('scroll', () => scrollShow(showMap, setShowMap));
    }, []);

    return (
        <div className='siteContent'>
            <Header pageName="Technology" option="RTI" />
            <BasicInfo
                text1=""
                text2="tech.rti"
                text3=" "
                bgSrc="technology_RTI.png"
                textTop={100} bgHeight={500} imgWidth={300} imgHeight={330} textWidth={1000}
            />

            {!showMap["intro"] && <div className='decoy' id="intro" />}
            {showMap["intro"] && <div className='technology-overview fade-in'>
                <div className='mont-bold-white-48px' style={{ marginBottom: 20, lineHeight: 1.4 }}>
                    {ReactHtmlParser(intl.formatMessage({ id: "tech.rti.title" }))}
                </div>
                <img src="/images/technology_overview_divider.svg" style={{ marginLeft: "auto", marginRight: "auto" }} />
                <div className="inter-normal-white-20px" style={{ padding: "50px 20px", lineHeight: 1.8, textAlign: "left" }}>
                    {ReactHtmlParser(intl.formatMessage({ id: "tech.rti.intro" }))}
                </div>
            </div>}

            {/* <DetailBlock
                imgSrc="blusensor-2.png"
                bgSrc="technology_bg.png"
                text="tech.dsi.detail"
                title="tech.dsi.detail.title"
                textTop={100}
            /> */}

            {!showMap["feature"] && <div className='decoy' id="feature" />}
            {showMap["feature"] && <FeatureBlock featureData={[0, 0, 0, 0, 30]} bgSrc="/images/earth-green.png" intlKey="rti" prefix="tech" />}

            {!showMap["relevant"] && <div className='decoy' id="relevant" />}
            {showMap["relevant"] && <TechRelevant bgSrc="technology_relevant_bg3.png" blucam={true} />}

            <Footer />
        </div>
    );
});

export { TechnologyOverview, TechDSI, TechTDOA, TechRTI };