const homeFeature = [
    {
        title: "home.feature1.title",
        imgSrc: "/images/home-feature1.png",
        content: "home.feature1.content"
    },
    {
        title: "home.feature2.title",
        imgSrc: "/images/feature-blusensor3.png",
        content: "home.feature2.content"
    },
    {
        title: "home.feature3.title",
        imgSrc: "/images/feature-blugun1.png",
        content: "home.feature3.content"
    },
    {
        title: "home.feature4.title",
        imgSrc: "/images/feature-blusensor4.png",
        content: "home.feature4.content"
    },
    {
        title: "home.feature5.title",
        imgSrc: "/images/home-feature5.png",
        content: "home.feature5.content"
    },
    {
        title: "home.feature6.title",
        imgSrc: "/images/feature-blucam4.png",
        content: "home.feature6.content"
    },
];

const hardF4 = [
    {
        imgSrc: "/images/home-blusensor-blue.png",
        content: "home.hard.blusensor",
        link: "/Products/Blusensor",
        left: 40
    },
    {
        imgSrc: "/images/home-blucam.png",
        content: "home.hard.blucam",
        link: "/Products/Blucam",
        left: 10
    },
    // {
    //     imgSrc: "/images/home-blushield.png",
    //     content: "home.hard.blushield",
    //     link: "/Products/Blushield",
    // },
    // {
    //     imgSrc: "/images/home-blugun.png",
    //     content: "home.hard.blugun",
    //     link: "/Products/Blugun",
    // },
    {
        imgSrc: "/images/BlucaseP5000-1.png",
        content: "home.hard.blucase",
        link: "/Products/Blucase",
    },
];

const softF4 = [
    {
        imgSrc: "/images/home-s1.png",
        content: "home.soft.dsi",
        link: "/Technology/DSI",
    },
    {
        imgSrc: "/images/home-s2.png",
        content: "home.soft.rti",
        link: "/Technology/RTI",
    },
    {
        imgSrc: "/images/home-s3.png",
        content: "home.soft.tdoa",
        link: "/Technology/TDOA",
    },
    {
        imgSrc: "/images/home-s4.png",
        content: "home.soft.soft",
        link: "/Products/Software",
    },
];

const careerFeature = [
    {
        content: "about.career.feature1",
        imgSrc: "/images/about-career-hours.png",
    },
    {
        content: "about.career.feature2",
        imgSrc: "/images/about-career-salary.png",
    },
    {
        content: "about.career.feature3",
        imgSrc: "/images/about-career-potential.png",
    },
    {
        content: "about.career.feature4",
        imgSrc: "/images/about-career-environment.png",
    },
    {
        content: "about.career.feature5",
        imgSrc: "/images/about-career-development.png",
    },
]

const productOverview = [
    {
        imgSrc: "BlucaseP5000-1.png",
        width: 500,
        decoy: "proShi",
    },
    {
        name: "Blucase P5000",
        href: "/Products/Blucase",
        msg: "product.case.intro",
        decoy: "proShi",
    },
    {
        name: "Blusensor Series",
        href: "/Products/Blusensor",
        msg: "product.blusensor.intro",
        decoy: "proSen",
    },
    {
        imgSrc: "blusensor-2-blue.png",
        width: 450,
        decoy: "proSen",
    },
    {
        imgSrc: "blucam.png",
        width: 420,
        decoy: "proCam",
    },
    {
        name: "Blucam A100",
        href: "/Products/Blucam",
        msg: "product.blucam.intro",
        decoy: "proCam",
    },
    // {
    //     name: "Blushield A8000",
    //     href: "/Products/Blushield",
    //     msg: "product.blushield.intro",
    //     decoy: "proShi",
    // },
    // {
    //     imgSrc: "blushield-ad8000-1.png",
    //     decoy: "proShi",
    // },
    // {
    //     imgSrc: "blugun.png",
    //     width: 500,
    //     decoy: "proGun",
    // },
    // {
    //     name: "Blugun",
    //     href: "/Products/Blugun",
    //     msg: "product.blugun.intro",
    //     decoy: "proGun",
    // },


]

const blusensorSpecs = [
    {
        text1: "product.blusensor.specs.tech",
        text2: "product.blusensor.specs.techContent",
        link: "/Technology/DSI"
    },
    {
        text1: "product.blusensor.specs.power",
        text2: "product.blusensor.specs.powerContent",
    },
    {
        text1: "product.blusensor.specs.geo",
        text2opt1: "product.blusensor.specs.geoContent1",
        text2opt2: "product.blusensor.specs.geoContent2",
        text2opt3: "product.blusensor.specs.geoContent3",
        text2LV: "product.blusensor.specs.geoContentLV",
    },
    {
        text1: "product.blusensor.specs.connect",
        text2: "product.blusensor.specs.connectContent",
    },
    {
        text1: "product.blusensor.specs.range",
        text2opt1: "product.blusensor.specs.rangeContent1",
        text2opt2: "product.blusensor.specs.rangeContent2",
        text2opt3: "product.blusensor.specs.rangeContent3",
        text2LV: "product.blusensor.specs.rangeContentLV",
        height: 150,
    },
    {
        text1: "product.blusensor.specs.config",
        text2: "product.blusensor.specs.configContent",
        height: 150,
    },
    {
        text1: "product.blusensor.specs.freq",
        text2: "product.blusensor.specs.freqContent",
        height: 150,
    },
    {
        text1: "product.blusensor.specs.soft",
        text2: "product.blusensor.specs.softContent",
        height: 150,
    },
    {
        text1: "product.blusensor.specs.dimension",
        text2: "product.blusensor.specs.dimensionContent",
        text2LV: "product.blusensor.specs.dimensionContentLV",
    },
    {
        text1: "product.blusensor.specs.sys",
        text2: "product.blusensor.specs.sysContent",
    },
    {
        text1: "product.blusensor.specs.weight",
        text2: "product.blusensor.specs.weightContent",
        text2LV: "product.blusensor.specs.weightContentLV",
    },
    {
        text1: "product.blusensor.specs.interfere",
        text2: "product.blusensor.specs.interfereContent",
    },
    {
        text1: "product.blusensor.specs.ingress",
        text2: "product.blusensor.specs.ingressContent",
    },
    {
        text1: "product.blusensor.specs.simul",
        text2: "product.blusensor.specs.simulContent",
    },
    {
        text1: "product.blusensor.specs.temp",
        text2: "product.blusensor.specs.tempContent",
    },
    {
        text1: "product.blusensor.specs.dire",
        text2: "product.blusensor.specs.direContent",
    },
    {
        text1: "product.blusensor.specs.supply",
        text2: "product.blusensor.specs.supplyContent",
    },
    {
        text1: "",
    },
    {
        text1: "",
    }
];

const blucamSpecs = [
    {
        text1: "product.blucam.specs.tech",
        text2: "product.blucam.specs.techContent",
        link: "/Technology/RTI",
    },
    {
        text1: "product.blucam.specs.method",
        text2: "product.blucam.specs.methodContent",
    },
    {
        text1: "product.blucam.specs.distance",
        text2: "product.blucam.specs.distanceContent",
    },
    {
        text1: "product.blucam.specs.accu",
        text2: "product.blucam.specs.accuContent",
        link: "/Resources/Article/pose-estimation-for-drones",
    },
    {
        text1: "product.blucam.specs.horizontal",
        text2: "product.blucam.specs.horizontalContent",
    },
    {
        text1: "product.blucam.specs.vertical",
        text2: "product.blucam.specs.verticalContent",
    },
    {
        text1: "product.blucam.specs.zoom",
        text2: "product.blucam.specs.zoomContent",
    },
    {
        text1: "product.blucam.specs.resolution",
        text2: "product.blucam.specs.resolutionContent",
    },
    {
        text1: "product.blucam.specs.time",
        text2: "product.blucam.specs.timeContent",
    },
    {
        text1: "product.blucam.specs.simul",
        text2: "product.blucam.specs.simulContent",
    },
    {
        text1: "product.blucam.specs.focal",
        text2: "product.blucam.specs.focalContent",
    },
    {
        text1: "product.blucam.specs.supply",
        text2: "product.blucam.specs.supplyContent",
    },
    {
        text1: "product.blucam.specs.weight",
        text2: "product.blucam.specs.weightContent",
    },
    {
        text1: "product.blucam.specs.ingress",
        text2: "product.blucam.specs.ingressContent",
    },
    {
        text1: "product.blucam.specs.temp",
        text2: "product.blucam.specs.temperature",
    },
    {
        text1: "product.blucam.specs.connect",
        text2: "product.blucam.specs.connectContent",
    },
    {
        text1: "product.blucam.specs.dimension",
        text2: "product.blucam.specs.dimensionContent",
    },
    {
        text1: "product.blucam.specs.input",
        text2: "product.blucam.specs.inputContent",
    },
    {
        text1: "product.blucam.specs.config",
        text2: "product.blucam.specs.configContent",
    },
    {
        text1: "product.blucam.specs.soft",
        text2: "product.blucam.specs.softContent",
    },
    {
        text1: "product.blucam.specs.interfere",
        text2: "product.blucam.specs.interfereContent",
    },
    {
        text1: "product.blucam.specs.sys",
        text2: "product.blucam.specs.sysContent",
    },
    {
        text1: "product.blucam.specs.storage",
        text2: "product.blucam.specs.storageContent",
    },
    {
        text1: "product.blucam.specs.humid",
        text2: "product.blucam.specs.humidContent",
    },
    {
        download: "/Blucam A100.pdf",
    },
    {
        text1: "product.blucam.specs.geo",
        text2: "product.blucam.specs.geoContent",
    }
];

const blugunSpecs = [
    {
        text1: "product.blugun.specs.tech",
        text2: "product.blugun.specs.techContent",
    },
    {
        text1: "product.blugun.specs.freq",
        text2: "product.blugun.specs.freqContent",
    },
    {
        text1: "product.blugun.specs.display",
        text2: "product.blugun.specs.displayContent",
    },
    {
        text1: "product.blugun.specs.distance",
        text2: "product.blugun.specs.distanceContent",
    },
    {
        text1: "product.blugun.specs.time",
        text2: "product.blugun.specs.timeContent",
    },
    {
        text1: "product.blugun.specs.temp",
        text2: "product.blugun.specs.tempContent",
    },
    {
        text1: "product.blugun.specs.range",
        text2: "product.blugun.specs.rangeContent",
    },
    {
        text1: "product.blugun.specs.accu",
        text2: "product.blugun.specs.accuContent",
    },
    {
        text1: "product.blugun.specs.weight",
        text2: "product.blugun.specs.weightContent",
    },
    {
        download: "/Blugun.pdf",
    },
    {
        text1: "product.blugun.specs.dimension",
        text2: "product.blugun.specs.dimensionContent"
    },
];

const blushieldSpecs = [
    {
        text1: "product.blushield.specs.tech",
        text2: "product.blushield.specs.techContent",
        link: "/Technology/DSI",
    },
    {
        text1: "product.blushield.specs.jamRange",
        text2: "product.blushield.specs.jamRangeContent",
    },
    {
        text1: "product.blushield.specs.distance",
        text2: "product.blushield.specs.distanceContent",
    },
    {
        text1: "product.blushield.specs.direction",
        text2: "product.blushield.specs.directionContent",
        link: "",
    },
    {
        text1: "product.blushield.specs.geoD",
        text2: "product.blushield.specs.geoDContent",
    },
    {
        text1: "product.blushield.specs.geoP",
        text2: "product.blushield.specs.geoPContent",
    },
    {
        text1: "product.blushield.specs.detFreq",
        text2: "product.blushield.specs.detFreqContent",
    },
    {
        text1: "product.blushield.specs.jamFreq",
        text2: "product.blushield.specs.jamFreqContent",
    },
    {
        text1: "product.blushield.specs.consumption",
        text2: "product.blushield.specs.consumptionContent",
    },
    {
        text1: "product.blushield.specs.connect",
        text2: "product.blushield.specs.connectContent",
    },
    {
        text1: "product.blushield.specs.config",
        text2: "product.blushield.specs.configContent",
    },
    {
        text1: "product.blushield.specs.supply",
        text2: "product.blushield.specs.supplyContent",
    },
    {
        text1: "product.blushield.specs.weight",
        text2: "product.blushield.specs.weightContent",
    },
    {
        text1: "product.blushield.specs.ingress",
        text2: "product.blushield.specs.ingressContent",
    },
    {
        text1: "product.blushield.specs.temp",
        text2: "product.blushield.specs.tempContent",
    },
    {
        text1: "product.blushield.specs.soft",
        text2: "product.blushield.specs.softContent",
    },
    {
        text1: "product.blushield.specs.dimension",
        text2: "product.blushield.specs.dimensionContent",
    },
    {
        text1: "product.blushield.specs.storage",
        text2: "product.blushield.specs.storageContent",
    },
    {
        text1: "product.blushield.specs.interfere",
        text2: "product.blushield.specs.interfereContent",
    },
    {
        text1: "product.blushield.specs.sys",
        text2: "product.blushield.specs.sysContent",
    },
    {
        text1: "product.blushield.specs.simul",
        text2: "product.blushield.specs.simulContent",
    },
    {
        download: "/Blushield A8000.pdf",
    },
    {
        text1: "product.blushield.specs.dire",
        text2: "product.blushield.specs.direContent",
    },
];

const blucaseSpecs = [
    {
        text1: "product.blucase.specs.tech",
        text2: "product.blucase.specs.techContent",
        link: "/Technology/DSI",
    },
    {
        text1: "product.blucase.specs.supportUAV",
        text2: "product.blucase.specs.supportUAVContent",
    },
    {
        text1: "product.blucase.specs.range",
        text2: "product.blucase.specs.rangeContent",
    },
    {
        text1: "product.blucase.specs.geolocation",
        text2: "product.blucase.specs.geolocationContent",
    },
    {
        text1: "product.blucase.specs.simultaneous",
        text2: "product.blucase.specs.simultaneousContent",
    },
    {
        text1: "product.blucase.specs.frequency",
        text2: "product.blucase.specs.frequencyContent",
    },
    {
        text1: "product.blucase.specs.display",
        text2: "product.blucase.specs.displayContent",
    },
    {
        text1: "product.blucase.specs.power",
        text2: "product.blucase.specs.powerContent",
    },
    {
        text1: "product.blucase.specs.Connectivity",
        text2: "product.blucase.specs.ConnectivityContent",
    },
    {
        text1: "product.blucase.specs.COA",
        text2: "product.blucase.specs.COAContent",
    },
    {
        text1: "product.blucase.specs.weight",
        text2: "product.blucase.specs.weightVContent",
    },
    {
        text1: "product.blucase.specs.ip",
        text2: "product.blucase.specs.ipContent",
    },
    {
        text1: "product.blucase.specs.temp",
        text2: "product.blucase.specs.tempVContent",
    },
    {
        text1: "product.blucase.specs.update",
        text2: "product.blucase.specs.updateContent",
    },
    {
        text1: "product.blucase.specs.powerV",
        text2: "product.blucase.specs.powerVContent",
    },
    {
        text1: "product.blucase.specs.ProductDimensions",
        text2: "product.blucase.specs.ProductDimensionsContent",
    },
    {
        text1: "product.blucase.specs.data",
        text2: "product.blucase.specs.dataContent",
    },
    {
        text1: "product.blucase.specs.battery",
        text2: "product.blucase.specs.batteryContent",
    },
    {
        text1: "product.blucase.specs.integration",
        text2: "product.blucase.specs.integrationContent",
    },
    {
        text1: "product.blucase.specs.himidity",
        text2: "product.blucase.specs.himidityContent",
    },
    {
        text1: "product.blucase.specs.trans",
        text2: "product.blucase.specs.transContent",
    },
    {
        text1: "product.blucase.specs.directivity",
        text2: "product.blucase.specs.directivityContent",
    },
    {
        text1: "product.blucase.specs.audio",
        text2: "product.blucase.specs.audioContent",
    },
    {
        text1: "product.blucase.specs.HInterface",
        text2: "product.blucase.specs.HInterfaceContent",
    },
    {
        download: "/Blucase P5000.pdf",
    },
    {
        text1: "product.blucase.specs.chargetime",
        text2: "product.blucase.specs.chargetimeContent",
    },

];

const Leadership = [
    {
        name: "Jack Jia",
        avatar: "/images/avatar_jack.jpeg",
        title: "about.team.ceo",
        intro: "about.team.intro1",
        size: "130px",
        link: "https://www.linkedin.com/in/ijackjia/",
    },
    {
        name: "Michael Pan",
        avatar: "/images/avatar_michael.jpeg",
        title: "about.team.dr",
        intro: "about.team.intro2",
        size: "120px",
        link: "https://www.linkedin.com/in/leyuan-pan-2381b884/",
    },
    {
        name: "Daniel Oskooei",
        avatar: "/images/avatar_daniel.jpeg",
        title: "about.team.ds",
        intro: "about.team.intro3",
        size: "120px",
        link: "https://www.linkedin.com/in/mahmood-oskooei/",
    },
    {
        name: "Tina Zlati",
        avatar: "/images/avatar_tina.jpeg",
        title: "about.team.do",
        intro: "about.team.intro4",
        size: "120px",
        link: "https://www.linkedin.com/in/tina-zlati-8a092411b/",
    },
];

const specialists = [
    {
        name: "Thomas Barter",
        avatar: "/images/avatar_thomas.jpeg",
        title: "about.team.sm",
        intro: "about.team.intro5",
        size: "130px",
        link: "https://www.linkedin.com/in/thomas-barter-787aa515/",
    },
    {
        name: "Devyn Yang",
        avatar: "/images/avatar_davyn.jpeg",
        title: "about.team.se",
        intro: "about.team.intro6",
        size: "120px",
        link: "https://www.linkedin.com/in/jihui-yang-20a8a9160/",
    },
    {
        name: "Robert Link",
        avatar: "/images/tdoa/RobertAvatar.jpeg",
        title: "about.team.se",
        intro: "about.team.intro7",
        size: "120px",
        link: "https://www.linkedin.com/in/robert-link-a7652b4/",
    },
    {
        name: "Tina He",
        avatar: "/images/DbKI/avatar.jpeg",
        title: "about.team.ml",
        intro: "about.team.intro8",
        size: "120px",
        link: "https://www.linkedin.com/in/tina-he-271209137/",
    },
    {
        name: "Sam Lai",
        avatar: "/images/post-estimate/avatar.jpeg",
        title: "about.team.ml",
        intro: "about.team.intro9",
        size: "135px",
        link: "https://www.linkedin.com/in/sam-kwun-ping-lai-834138220/",
    },
]

const OurStory = [
    "https://www.linkedin.com/posts/bluvec_bluteam-cybersecurity-antidrone-activity-6945791433505218560-3Ufp?utm_source=share&utm_medium=member_desktop",
    "https://www.linkedin.com/posts/bluvec_blusensor-passive-fastdetection-activity-6986813168392040448-Rz5K?utm_source=share&utm_medium=member_desktop",
    "https://www.linkedin.com/posts/bluvec_team-teamculture-awardwinningteam-activity-6991085113493135360-IOdX?utm_source=share&utm_medium=member_desktop",
    "https://www.linkedin.com/posts/bluvec_bluvec-antidrone-dronethreats-activity-6984217023782285313-Uh36?utm_source=share&utm_medium=member_desktop",
    "https://www.linkedin.com/posts/bluvec_cybersecurity-antidrone-dronethreats-activity-6985632029631549440-ih2w?utm_source=share&utm_medium=member_desktop",
    "https://www.linkedin.com/posts/bluvec_biketowork-machinelearning-technology-activity-6933460264470159361-JtGc?utm_source=share&utm_medium=member_desktop",
];

export {
    productOverview, Leadership, OurStory, blusensorSpecs, blucamSpecs, blugunSpecs, blushieldSpecs,
    homeFeature, hardF4, softF4, careerFeature, specialists, blucaseSpecs
};