import React, { useState, useEffect } from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import RequestBlock from '../components/layout/Request';
import ReactHtmlParser from 'react-html-parser';
import { injectIntl } from 'react-intl';
import SolutionBlock from '../components/layout/Solution';
import { isInViewport, scrollShow } from '../utils/RenderIfVisible';
import { isMobile } from '../utils/MobileAdapt';

const SolutionOverview = injectIntl((props) => {
    const { intl } = props;

    const [showMap, setShowMap] = useState({
        "ovIntro": false,
        "ovFlow": false,
        "ovFea": false,
    });

    useEffect(() => {
        window.addEventListener('scroll', () => scrollShow(showMap, setShowMap));
    }, []);

    return (
        <div className="siteContent">
            <Header pageName="Solutions" option="solutionOverview" />
            <button className='title-image' style={{ height: 530, backgroundImage: `url(/images/solution_overview.png)` }}>
                <h1 className="mont-bold-white-48px fade-in" >{intl.formatMessage({ id: "solution.overview.title" })}</h1>
            </button>

            {!showMap["ovIntro"] && <div className='decoy' id="ovIntro" />}
            {showMap["ovIntro"] && <div className='solution-bg mont-semi-bold-white-45px fade-in-up-big'>
                <p className='solution-bg-text'>{intl.formatMessage({ id: "solution.overview.intro" })}</p>
            </div>}

            {!showMap["ovFlow"] && <div className='decoy' id="ovFlow" />}
            {showMap["ovFlow"] && <div className='solution-workflow fade-in-up-big' >
                <div className='solution-title'>
                    <div className="mont-semi-bold-denim-48px">{intl.formatMessage({ id: "solution.overview.title3" })}</div>
                    <img src="/images/solution_divider6.svg" style={{ marginLeft: "auto", marginRight: "auto", marginTop: 20, marginBottom: 20 }} loading="lazy" />
                </div>
                <div className='solution-workflow-item'>
                    <span className="mont-black-pizazz-80px">1</span>
                    <img src="/images/solution_divider7.svg" style={{ position: "absolute", left: 60, top: 0 }} loading="lazy" />
                    <img src="/images/feature_detection.png" style={{ position: "absolute", left: 120, top: 10 }} loading="lazy" />
                    <div className="mont-semi-bold-white-25px" style={{ fontSize: 36, marginTop: 100, marginLeft: 60, textAlign: "center" }}>
                        {intl.formatMessage({ id: "solution.overview.feature1" })}
                    </div>
                </div>
                <div className='solution-workflow-item'>
                    <span className="mont-black-pizazz-80px">2</span>
                    <img src="/images/solution_divider7.svg" style={{ position: "absolute", left: 60, top: 0 }} loading="lazy" />
                    <img src="/images/feature_drone.png" style={{ position: "absolute", left: 120, top: 10 }} loading="lazy" />
                    <div className="mont-semi-bold-white-25px" style={{ fontSize: 36, marginTop: 100, marginLeft: 60, textAlign: "center" }}>
                        {intl.formatMessage({ id: "solution.overview.feature2" })}
                    </div>
                </div>
                <div className='solution-workflow-item'>
                    <span className="mont-black-pizazz-80px">3</span>
                    <img src="/images/solution_divider7.svg" style={{ position: "absolute", left: 60, top: 0 }} loading="lazy" />
                    <img src="/images/feature_defense.png" style={{ position: "absolute", left: 120, top: 10 }} loading="lazy" />
                    <div className="mont-semi-bold-white-25px" style={{ fontSize: 36, marginTop: 100, marginLeft: 60, textAlign: "center" }}>
                        {intl.formatMessage({ id: "solution.overview.feature3" })}
                    </div>
                </div>
            </div>}

            {!showMap["ovFea"] && <div className='decoy' id="ovFea" />}
            {showMap["ovFea"] && <div style={{ marginTop: 160, marginBottom: 160, position: "relative" }} className="fade-in">
                <div className='howItWorks-background' />
                <div className='solution-title'>
                    <div className="mont-semi-bold-denim-48px">{intl.formatMessage({ id: "solution.overview.title2" })}</div>
                    <img alt="divider" src="/images/solution_divider6.svg" style={{ marginLeft: "auto", marginRight: "auto", marginTop: 20, marginBottom: 20 }} />
                </div>
                <SolutionBlock hideEffect={true} />
            </div>}

            <RequestBlock />
            <Footer />
        </div>
    )
});

const CurrentSituationBlock = injectIntl((props) => {
    const { text, threatArray, intl, intlKey } = props;
    return (
        <div className='situation row-wrap fade-in'>
            <div style={{ width: 600 }}>
                <div className="mont-semi-bold-denim-48px" >{intl.formatMessage({ id: "solution.cs" })}</div>
                <img src="/images/solution_divider.svg" style={{ marginTop: 20 }} loading="lazy" />
                <p className="mont-regular-normal-white-20px" style={{ marginTop: 50, lineHeight: 1.8 }}>
                    {ReactHtmlParser(intl.formatMessage({ id: text }))}
                </p>
            </div>
            <div className='situation-threats mont-regular-normal-white-30px'>
                <span className='mont-bold-white-30px' style={{ lineHeight: 1.6, fontSize: 30 }}>
                    {intl.formatMessage({ id: "solution.threat" })}<br />{intl.formatMessage({ id: `header.solution.${intlKey}` })}:
                </span>
                <ul style={{ lineHeight: 1.5, fontSize: isMobile() ? 20 : 26 }}>
                    {threatArray.map(index => <li>{intl.formatMessage({ id: `solution.${intlKey}.threat${index + 1}` })}</li>)}
                </ul>
            </div>
            <div className='detail-transition-2' style={{ left: 800, top: 100 }} />
        </div>
    );
});

const CommentCard = injectIntl((props) => {
    const { text1, text2, intl, link } = props;
    return (
        <div className='comment-card fade-in margin-center'>
            <span className='quotation-mark mont-black-pizazz-200px' style={{ color: "white", top: 20, fontSize: 120 }} >“</span>
            <div className="mont-regular-normal-white-25px comment-words">
                <p className='absolute-center' style={{ width: "90%", lineHeight: 1.6, marginTop: 20, fontSize: 20 }}>
                    <a href={link} target="_blank">{intl.formatMessage({ id: text1 })}</a>
                </p>
            </div>
            <img src="/images/solution_divider3.svg" style={{ width: "100%" }} />
            <div style={{ position: "relative", height: 120 }}>
                <div className="mont-regular-normal-white-25px absolute-center" style={{ width: "90%", textAlign: "center", lineHeight: 1.6, fontSize: 18 }}>
                    <a href={link} target="_blank">{ReactHtmlParser(intl.formatMessage({ id: text2 }))}</a>
                </div>
            </div>
        </div>
    );
});

const CommentSlide = injectIntl((props) => {
    const { comment, intlKey } = props;
    const [show, setShow] = useState(0);
    const length = comment.length;

    return (
        <div>
            <div className="slideshow-container fade-in-up">
                {
                    comment.map((link, index) => show === index &&
                        <CommentCard text1={`solution.${intlKey}.words${index + 1}`} text2={`solution.${intlKey}.author${index + 1}`} link={link} />
                    )
                }
                <a className="slide-prev" style={{ marginLeft: "10%" }} onClick={() => { setShow((show + length - 1) % length) }}>&#10094;</a>
                <a className="slide-next" style={{ marginRight: "9%" }} onClick={() => { setShow((show + 1) % length) }}>&#10095;</a>
            </div>
            <div style={{ textAlign: "center" }}>
                {
                    comment.map((_, index) => <span className={"slide-dot " + (show === index ? "dot-active" : "")} onClick={() => setShow(index)} />)
                }
            </div>
        </div>
    );
});

const ImgSlide = injectIntl((props) => {
    const { imgData, intl } = props;
    const length = imgData.length;
    const [show, setShow] = useState(0);
    const [hide, setHide] = useState({});
    const [trans, setTrans] = useState("");

    const handleHover = (device) => {
        const temp = { ...hide };
        temp[device] = true;
        setHide(temp);
        hide[device] = false;
        setTimeout(() => setHide(hide), 100);
    };

    return (
        <div>
            <div className="slideshow-container">
                {
                    imgData.map((img, index) => show === index &&
                        <div className='margin-center row-wrap sol-img-slide' style={{ flexFlow: isMobile() ? "row nowrap" : "row wrap" }}>
                            <div style={{ position: "relative" }}>
                                <img
                                    style={{ position: "relative" }}
                                    src={`/images/${img.imgSrc}`} key={index} loading="lazy"
                                    className="solution-slide-img fade-in"
                                />
                                {img.blucam && !hide["blucam"] &&
                                    <img
                                        className='bounce-in'
                                        src={`/images/${img.blucam.sign}`} width={img.blucam.width}
                                        style={{ position: "absolute", top: img.blucam.top, left: img.blucam.left }}
                                    />}
                                {img.blusensor && !hide["blusensor"] &&
                                    <img
                                        className='bounce-in'
                                        src={`/images/${img.blusensor.sign}`} width={img.blusensor.width}
                                        style={{ position: "absolute", top: img.blusensor.top, left: img.blusensor.left }}
                                    />}
                            </div>
                            {(img.blucam || img.blusensor) && <div className='sol-img-rightbar'>
                                <div className='mont-semi-bold-white-25px'>
                                    {intl.formatMessage({ id: "solution.dl" })}
                                </div>
                                {img.blucam &&
                                    <a href="/Products/Blucam" style={{ position: "relative" }}
                                        onMouseEnter={() => { setTrans("cam"); handleHover("blucam") }} onMouseLeave={() => setTrans("")}>
                                        {trans === "cam" && <div className='sol-device-trans' />}
                                        <img src="/images/blucam.png" style={{ height: 150, cursor: "pointer" }} className="margin-center" />
                                        <p className='mont-regular-normal-white-18px'>Blucam A100 * {img.blucam.num}</p>
                                    </a>}
                                {img.blusensor &&
                                    <a href="/Products/Blusensor" style={{ position: "relative" }}
                                        onMouseEnter={() => { setTrans("sensor"); handleHover("blusensor") }} onMouseLeave={() => setTrans("false")}>
                                        {trans === "sensor" && <div className='sol-device-trans' />}
                                        <img src="/images/blusensor-2-blue.png" style={{ height: 150, cursor: "pointer" }} className="margin-center" />
                                        <p className='mont-regular-normal-white-18px'>Blusensor {img.blusensor.type} * {img.blusensor.num}</p>
                                    </a>}
                            </div>}
                        </div>
                    )
                }
                <a className="slide-prev" style={{ marginLeft: "10%" }} onClick={() => { setShow((show + length - 1) % length) }}>&#10094;</a>
                <a className="slide-next" style={{ marginRight: "9%" }} onClick={() => { setShow((show + 1) % length) }}>&#10095;</a>
            </div>
            <div style={{ textAlign: "center" }}>
                {
                    imgData.map((_, index) => <span className={"slide-dot " + (show === index ? "dot-active" : "")} onClick={() => setShow(index)} />)
                }
            </div>
        </div>
    );
});

const SolutionCard = injectIntl((props) => {
    const { titleImg, pageOption, intlKey, comment, imgData, intl, featureLength, threatLength } = props;
    const featureArray = Array.from(Array(featureLength).keys());
    const threatArray = Array.from(Array(threatLength).keys());
    const [showSlide, setShowSlide] = useState(false);
    const [showFeat, setShowFeat] = useState(false);
    const [showCom, setShowCom] = useState(false);
    const [showCS, setShowCS] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (isInViewport("sol-slide")) {
                setShowSlide(true);
            };
            if (isInViewport("sol-feature")) {
                setShowFeat(true);
            };
            if (isInViewport("sol-comment")) {
                setShowCom(true);
            };
            if (isInViewport("sol-cs")) {
                setShowCS(true);
            };
        });
    }, []);
    return (
        <div className="siteContent">
            <Header pageName="Solutions" option={pageOption} />

            <button className='title-image' style={{ height: 530, backgroundImage: `url(/images/${titleImg})` }}>
                <h1 className="mont-bold-white-80px fade-in" >{intl.formatMessage({ id: `header.solution.${intlKey}` })}</h1>
            </button>

            {!showCS && <div id="sol-cs" style={{ position: "relative", top: 350, height: 500 }} />}
            {showCS && <CurrentSituationBlock
                text={`solution.${intlKey}.cs`}
                threatArray={threatArray}
                intlKey={intlKey}
            />}

            {!showCom && <div id="sol-comment" style={{ position: "relative", top: 350, height: 500 }} />}
            {showCom && <div className='word-of-mouth common-wrapper'>
                <div className="mont-semi-bold-denim-48px fade-in" style={{ position: "relative", padding: isMobile() ? "0 50px" : "0 120px" }}>
                    <span className='quotation-mark mont-black-pizazz-200px' style={{ left: 0 }} >“</span>
                    {intl.formatMessage({ id: "solution.word" })}
                    <span className='quotation-mark mont-black-pizazz-200px' style={{ left: localStorage.getItem("locale") === "en_US" ? 550 : 700 }}>”</span>
                </div>
                <img src="/images/solution_divider2.svg" style={{ marginTop: 20, marginBottom: 40 }} />
                <CommentSlide comment={comment} intlKey={intlKey} />
            </div>}

            {!showSlide && <div id="sol-slide" style={{ position: "relative", top: 400, height: 500 }} />}
            {showSlide && <div style={{ padding: isMobile() ? "50px 0px" : "50px", marginBottom: 200 }} className="fade-in common-wrapper">
                <div className="mont-semi-bold-denim-48px" >{intl.formatMessage({ id: "solution.works" })}</div>
                <img src="/images/solution_divider.svg" style={{ marginTop: 20 }} loading="lazy" />
                <ImgSlide imgData={imgData} />
                <div id="sol-feature" style={{ position: "relative", top: 400 }} />
                {showFeat && <div className='row-wrap mont-semi-bold-white-25px bounce-in' style={{ rowGap: 50 }}>
                    {
                        featureArray.map(index =>
                            <div className='row-wrap' style={{ alignItems: "center", gap: "50px 50px" }}>
                                {index % 2 !== 0 &&
                                    <img
                                        src={`/images/solution-${intlKey}-feature${index + 1}.png`}
                                        className="sol-fea-img" style={{ marginLeft: 200, height: 100 }}
                                    />}
                                <div className='sol-fea-item'>
                                    <p className='sol-fea-text'>{intl.formatMessage({ id: `solution.${intlKey}.feature${index + 1}` })}</p>
                                    <img src="/images/solution-feature-divider.png" style={{ height: 2, objectFit: "cover" }} />
                                </div>
                                {index % 2 === 0 &&
                                    <img
                                        src={`/images/solution-${intlKey}-feature${index + 1}.png`}
                                        className="sol-fea-img" style={{ marginRight: 200, height: 100 }}
                                    />}
                            </div>
                        )
                    }
                </div>}
            </div>}

            <RequestBlock />
            <Footer />
        </div>
    );
});

const SolutionAirport = () => {
    return (
        <SolutionCard
            titleImg="solution_airports_big.png"
            pageOption="airports"
            intlKey="airport"
            comment={["https://www.theguardian.com/uk-news/2020/dec/01/the-mystery-of-the-gatwick-drone",]}
            featureLength={4}
            threatLength={3}
            imgData={[
                {
                    "imgSrc": "solution-airport-1.png",
                    "blucam": { "num": 16, "sign": "airportsYellow16.svg", "top": "41.5%", "left": "40.5%", "width": "17%" },
                    "blusensor": { "type": "A1000", "num": 1, "sign": "GreenCircle.svg", "top": "48.5%", "left": "48.5%", "width": "2.5%" },
                },
            ]}
        />
    );
};

const SolutionCorrectional = () => {
    return (
        <SolutionCard
            titleImg="solution_correctional_big.png"
            pageOption="correctional"
            intlKey="prison"
            comment={["https://www.cbc.ca/news/politics/corrections-canada-drones-drugs-prisons-1.5164531",]}
            featureLength={4}
            threatLength={3}
            imgData={[
                {
                    "imgSrc": "Correctional 1.png",
                },
                {
                    "imgSrc": "Correctional 2.png",
                    "blucam": { "num": 1, "sign": "CorrectionalImg2Yellow.svg", "top": "44.6%", "left": "48.5%", "width": "7%" },
                },
                {
                    "imgSrc": "Correctional 3.png",
                    "blucam": { "num": 1, "sign": "CorrectionalImg3Yellow.svg", "top": "41%", "left": "54.5%", "width": "3.5%" },
                    "blusensor": { "type": "A1000", "num": 4, "sign": "GreenCircle.svg", "top": "43.6%", "left": "55.6%", "width": "2%" },
                }
            ]}
        />
    );
};

const SolutionInfrastructure = () => {
    return (
        <SolutionCard
            titleImg="solution_critical_big.png"
            pageOption="infrastructure"
            intlKey="infra"
            comment={["https://www.reuters.com/article/us-france-nuclear-greenpeace/greenpeace-crashes-superman-shaped-drone-into-french-nuclear-plant-idUSKBN1JT1JM",]}
            featureLength={4}
            threatLength={3}
            imgData={[{ "imgSrc": "Critcal Infrastructure.gif" }]}
        />
    );
};

const SolutionPublic = () => {
    return (
        <SolutionCard
            titleImg="solution_public_big.png"
            pageOption="public"
            intlKey="public"
            comment={["https://mynorthwest.com/3651672/drone-delays-seahawks-huskies-games-highlight-growing-problem/",]}
            featureLength={4}
            threatLength={4}
            imgData={[
                {
                    "imgSrc": "Public.jpeg",
                    "blusensor": { "type": "A1000", "num": 1, "sign": "GreenCircle.svg", "top": "45%", "left": "49%", "width": "2.5%" },
                },
            ]}
        />
    );
};

const SolutionMilitary = () => {
    return (
        <SolutionCard
            titleImg="solution_military_big.png"
            pageOption="military"
            intlKey="military"
            comment={["https://www.aljazeera.com/news/2022/10/20/mass-drones-are-a-worry-for-the-future-of-warfare",]}
            featureLength={4}
            threatLength={3}
            imgData={[
                {
                    "imgSrc": "military1.png",
                    "blucam": { "num": 1, "sign": "YellowCircle.svg", "top": "47%", "left": "54.5%", "width": "2.5%" },
                },
                {
                    "imgSrc": "military2.png",
                    "blucam": { "num": 1, "sign": "YellowCircle.svg", "top": "46%", "left": "54.5%", "width": "2.4%" },
                    "blusensor": { "type": "A1000", "num": 4, "sign": "solution-prison-circle-2.svg", "top": "42%", "left": "52%", "width": "7%" },
                },
                {
                    "imgSrc": "military3.png",
                    "blusensor": { "type": "A8000", "num": 4, "sign": "solution-prison-circle-3.svg", "top": "39%", "left": "48%", "width": "9.5%" },
                },
            ]}
        />
    );
};

const SolutionCommercial = () => {
    return (
        <SolutionCard
            titleImg="solution_commercial_big.png"
            pageOption="commercial"
            intlKey="commercial"
            comment={['https://www.princegeorgecitizen.com/local-news/police-investigating-drone-spying-on-children-5229570']}
            featureLength={4}
            threatLength={3}
            imgData={[
                {
                    "imgSrc": "Commercial 1.png",
                },
                {
                    "imgSrc": "Commercial 2.png",
                    "blusensor": { "type": "A1000", "num": 1, "sign": "YellowCircle.svg", "top": "50%", "left": "47.5%", "width": "2.5%" },
                },
                {
                    "imgSrc": "solution-commercial-3.png",
                    "blusensor": { "type": "A1000", "num": 1, "sign": "GreenCircle.svg", "top": "46%", "left": "48.5%", "width": "2.5%" },
                },
            ]}
        />
    );
};

export { SolutionOverview, SolutionAirport, SolutionCommercial, SolutionInfrastructure, SolutionPublic, SolutionMilitary, SolutionCorrectional };