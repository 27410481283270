import React, { useEffect, useState } from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import ReactHtmlParser from 'react-html-parser';
import droneNews from '../data/droneNews';
import industryEvent from '../data/industryEvent';
import articles from '../data/articles';
import { injectIntl } from 'react-intl';
import HubspotContactForm from './Hubspot';
import { handleDownload } from '../utils/HubspotUtils';
import { isMobile } from '../utils/MobileAdapt';

const DownloadModal = injectIntl((props) => {
    const { intl } = props;
    return (
        <div id="downloadModal" className="my-modal" style={{ display: "none" }}>
            <div className="my-modal-content fade-in-up">
                <div className='close' onClick={() => document.getElementById("downloadModal").style.display = "none"}>&times;</div>
                <p style={{ marginTop: 50, lineHeight: 1.4 }}>{intl.formatMessage({ id: "download.hint" })}</p>
                <HubspotContactForm
                    region="na1"
                    portalId="22076592"
                    formId="851fb021-375a-44eb-9725-34ef917e0bac"
                    target="hubspotDownload"
                />
            </div>
        </div>
    );
})


const WebinarItem = injectIntl((props) => {
    const { time, title, host, link, intl, buttonName, height } = props;
    return (
        <div className='marketing-webinar-item' style={{ height: height ? height : 900 }}>
            <img src="/images/resource-webinar.png" width={415} />
            <div>
                <div className='marketing-webinar-item-text'>
                    <div className="mont-regular-normal-white-20px" style={{ fontSize: 15 }}>
                        {ReactHtmlParser(intl.formatMessage({ id: time }))}
                    </div>
                    <div className="mont-bold-white-20px" style={{ color: "var(--pizazz)" }}>
                        {ReactHtmlParser(intl.formatMessage({ id: title }))}
                    </div>
                    <p className="mont-regular-normal-white-20px" style={{ fontSize: 15, }}>
                        {ReactHtmlParser(intl.formatMessage({ id: host }))}
                    </p>
                </div>
                <a href={link} target="_blank">
                    <button className="blusensor-learnMore" style={{ bottom: 0 }}>
                        {intl.formatMessage({ id: buttonName ? buttonName : "general.watch" })}
                    </button>
                </a>
            </div>
        </div>
    );
})

const ResourceMarketing = injectIntl((props) => {
    const { intl, pptData, FAQnum, webinarData } = props;
    const FAQarray = Array.from(Array(FAQnum).keys());

    const [ansObj, setAnsObj] = useState({});

    const showAnswer = (id) => {
        const newAnsObj = { ...ansObj };
        newAnsObj[id] = true;
        setAnsObj(newAnsObj);
    };
    const hideAnswer = (id) => {
        const newAnsObj = { ...ansObj };
        newAnsObj[id] = false;
        setAnsObj(newAnsObj);
    };

    const FAQItem = (props) => {
        const { id, question, ans } = props;
        return (
            <div className="marketing-FAQ-item">
                <div className="marketing-FQA-subItem mont-semi-bold-white-25px">
                    <div style={{ fontSize: isMobile() ? 20 : 30, marginRight: 50 }}>{intl.formatMessage({ id: question })}</div>
                    {ansObj[id] && <div className="arrow arrow-down" onClick={() => hideAnswer(id)} />}
                    {!ansObj[id] && <div className="arrow arrow-left" onClick={() => showAnswer(id)} />}
                </div>
                <img src="/images/resource-marketing-divider.svg" />
                {ansObj[id] && <div className='marketing-FAQ-answer mont-semi-bold-white-25px' id={id}>
                    {ReactHtmlParser(intl.formatMessage({ id: ans }))}
                </div>}
            </div>
        );
    };

    return (
        <div className='res-marketing fade-in' id="marketingSection">
            <a id="ppt" />
            <div className='marketing-option-flex mont-bold-white-30px'>
                <a href='#ppt'>{intl.formatMessage({ id: "res.market.all" })}</a>
                <a href='#ppt'>{intl.formatMessage({ id: "res.market.ppt" })}</a>
                <a href='#catalogue'>{intl.formatMessage({ id: "res.market.catalog" })}</a>
                <a href='#video'>{intl.formatMessage({ id: "res.market.video" })}</a>
                <a href='#webinar'>{intl.formatMessage({ id: "res.market.webinar" })}</a>
                <a href='#FAQ'>{intl.formatMessage({ id: "res.market.faq" })}</a>
            </div>
            <div className='marketing-ppt'>
                <div className="mont-bold-white-48px" style={{ textAlign: "center" }}>{intl.formatMessage({ id: "res.market.ppt" })}</div>
                <div className='marketing-ppt-flex'>
                    {
                        pptData.map((link, index) =>
                            <div className='marketing-ppt-item'>
                                <img src={`/images/resource-ppt${index + 1}.png`} alt={`resource-ppt-${index + 1}`} style={{ height: 305 }} />
                                <div className="mont-regular-normal-white-30px"
                                    style={{ marginTop: 30, marginBottom: 30, textAlign: "center" }}>
                                    {intl.formatMessage({ id: `res.ppt.title${index + 1}` })}
                                </div>
                                <a href={link} download onClick={handleDownload}>
                                    <button className="blusensor-learnMore">
                                        {intl.formatMessage({ id: "general.download" })}
                                    </button>
                                </a>
                            </div>
                        )
                    }
                </div>
            </div>

            <a id="catalogue" />
            <div className='marketing-catalogue'  >
                <div className="mont-bold-white-48px" style={{ textAlign: "center" }}>{intl.formatMessage({ id: "res.market.catalog" })}</div>
                <img className='image-center' src="/images/resource-catalogue.png" width={289} />
                <a href="/Bluvec-Catalogue.pdf" download onClick={handleDownload}>
                    <button className="blusensor-learnMore">
                        {intl.formatMessage({ id: "general.download" })}
                    </button>
                </a>
            </div>

            <a id="video" />
            <div className='marketing-video'>
                <div className="mont-bold-white-48px" style={{ textAlign: "center" }}>{intl.formatMessage({ id: "res.market.video" })}</div>
                <div className='marketing-video-flex'>
                    <iframe style={{ maxWidth: "90vw", height: isMobile() ? 300 : "420" }} width="600" height="338" src="https://www.youtube.com/embed/OtFCalAyESc" title="Blucam A100 7 Festures Introduction" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    {/* <iframe style={{ maxWidth: "90vw", height: isMobile() ? 300 : "420" }} width="600" height="338" src="https://www.youtube.com/embed/zllTjM_8AMA" title="Blugun Quick Start Guide" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
                </div>
            </div>

            <a id="webinar" />
            <div className='marketing-webinar' >
                <div className="mont-bold-white-48px" style={{ textAlign: "center" }}>{intl.formatMessage({ id: "res.market.webinar" })}</div>
                <div className='marketing-webinar-flex'>
                    {
                        webinarData.map((link, index) =>
                            <WebinarItem
                                time={`res.web.time${index + 1}`}
                                host={`res.web.host${index + 1}`}
                                title={`res.web.title${index + 1}`}
                                link={link}
                            />
                        )
                    }
                </div>
            </div>

            <div className='marketing-FAQ common-wrapper'>
                <a id="FAQ" />
                <div className="mont-bold-white-48px" style={{ textAlign: "center" }}>{intl.formatMessage({ id: "res.market.faq" })}</div>
                {
                    FAQarray.map(index =>
                        <FAQItem
                            id={"ans" + (index + 1)}
                            question={"res.faq.q" + (index + 1)}
                            ans={"res.faq.a" + (index + 1)}
                        />
                    )
                }
            </div>
            <DownloadModal />
        </div>
    );
})

const ResourceNews = () => {
    const numPage = Math.floor(droneNews.length / 10) + 1;
    const columnGap = [450, 180, 180, 180];
    const pageArray = Array.from(Array(numPage).keys());
    const [page, setPage] = useState(1);

    return (
        <div id="newsSection">
            {pageArray.map(index =>
                <div className='res-news mont-regular-normal-white-20px' id={`newsPage${index + 1}`} style={{ display: (index + 1) === page ? "flex" : "none" }}>
                    <div className="res-news-flex mont-bold-white-30px" >
                        <div style={{ width: columnGap[0] }}>News</div>
                        <div style={{ width: columnGap[1] }}>Industry</div>
                        <div style={{ width: columnGap[2] }}>Region</div>
                        <div style={{ width: columnGap[3] }}>Date</div>
                        <div>Link</div>
                    </div>
                    {
                        droneNews.slice(index * 10, (index + 1) * 10).map(news =>
                            <div>
                                <div className='res-news-flex mont-regular-normal-white-20px'>
                                    <div style={{ width: columnGap[0] }}>{news.title}</div>
                                    <div style={{ width: columnGap[1] }}>{news.industry}</div>
                                    <div style={{ width: columnGap[2] }}>{news.region}</div>
                                    <div style={{ width: columnGap[3] }}>{news.date}</div>
                                    <a href={`${news.link}`} target="_blank" style={{ color: "var(--denim)" }}>Read More</a>
                                </div>
                                <img src="/images/resource-news-divider.png" style={{ marginTop: 20 }} />
                            </div>
                        )
                    }
                </div>
            )}
            <div className='res-news-page-flex mont-regular-normal-white-30px' style={{ fontSize: 40 }}>
                <img src="/images/resource-news-arrowL.png" width={38} />
                {pageArray.map(index =>
                    <div className='res-news-pagenum' style={{ borderColor: (index + 1) === page ? "var(--denim)" : "transparent" }}
                        onClick={() => setPage(index + 1)}>
                        {index + 1}
                    </div>)}
                <img src="/images/resource-news-arrowR.png" width={38} />
            </div>
        </div>
    );
};

const ResourceLinkedin = () => {
    const [loading, setLoading] = useState(true);
    const [myInterval, setMyInterval] = useState(null);
    const [cancelInterval, setCancelInterval] = useState(false);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://platform.twitter.com/widgets.js";
        document.body.appendChild(script);
        let loadingInt = setInterval(() => {
            if (document.getElementById("tweet-link")) {
                setLoading(true);
            } else {
                setLoading(false);
                setCancelInterval(true);
            };
        }, 200);
        setMyInterval(loadingInt);
    }, []);

    useEffect(() => {
        clearInterval(myInterval);
    }, [cancelInterval]);

    return (
        <div style={{ padding: "40px 0px", maxWidth: "90vw", width: 1000 }} className="margin-center">
            {loading && <div style={{ padding: "100px 0px" }}>
                <div className='circle loader margin-center' />
                <h3 style={{ marginTop: 50 }}>Loading ...</h3>
            </div>}
            <a id="tweet-link" className="twitter-timeline" data-lang="en" data-theme="dark" href="https://twitter.com/BluvecTech?ref_src=twsrc%5Etfw"></a>
        </div>
    );
};

const ResourceIndustry = (props) => {
    const { industryEvents } = props;
    return (
        <div className='res-industry' id="industrySection">
            <div className='res-industry-flex'>
                {industryEvents.map((link, index) =>
                    <WebinarItem
                        time={`res.industry.time${index + 1}`}
                        title={`res.industry.title${index + 1}`}
                        host={`res.industry.host${index + 1}`}
                        link={link}
                        buttonName="general.learnMore"
                    />
                )}
            </div>
        </div>
    );
};

const ResourcePress = injectIntl((props) => {
    const { intl } = props;

    return (
        <div className="res-press" id="pressSection">
            <div className='res-industry-flex' style={{ columnGap: 60 }}>
                {articles.map(item =>
                    <a href={`/Resources/Article/${item.title.replace(/\s+/g, '-').toLowerCase()}`} style={{ height: 550 }}>
                        <div className='res-press-item fade-in'>
                            <img src={`${item.coverImage}`} width={400} />
                            <div className='res-industry-itemText' style={{ padding: "20px 20px" }}>
                                <div className="mont-regular-normal-white-20px" style={{ fontSize: 24 }}>{item.title}</div>
                                <img src="/images/resource-industry-divider.png" />
                                <div className='mont-regular-normal-white-20px'>{intl.formatMessage({ id: item.intro })}</div>
                            </div>
                            {item.new && <img src="/images/new-tag.png" style={{ position: "absolute", width: 100, top: -10, left: -11 }} />}
                        </div>
                    </a>
                )}
            </div>
        </div >
    );
});

const ResourcePage = injectIntl((props) => {
    const { intl } = props;

    const [option, setOption] = useState("marketing");

    //keep section when reload
    useEffect(() => {
        const section = sessionStorage.getItem("resourceSection");
        if (section) {
            handleSelect(section);
        };
    }, []);

    const handleSelect = (id) => {
        const curElement = document.getElementById(option);
        const selElement = document.getElementById(id);
        if (curElement && curElement.style) {
            curElement.style.border = "4px solid var(--blue)";
            curElement.style.borderBottom = "4px solid var(--pizazz)";
        };
        if (selElement && selElement.style) {
            selElement.style.border = "4px solid var(--pizazz)";
            selElement.style.borderBottom = "0px";
        };
        setOption(id);
        sessionStorage.setItem("resourceSection", id);
        window.location.hash = `#${id}`;
    };

    return (
        <div className='siteContent'>
            <Header pageName="Resources" option="market" />
            <div className='resource-bg fade-in'>
                <div className='res-title'>
                    <div className="mont-semi-bold-white-45px"> {intl.formatMessage({ id: "res.subtitle" })}</div>
                    <div className="mont-bold-white-80px"> {intl.formatMessage({ id: "res.title" })}</div>
                </div>
            </div>
            <div className="resource-option-flex">
                <div className="resource-option-edge" />
                <div id="marketing" className="resource-option-item resource-option-selected mont-semi-bold-white-25px"
                    onClick={() => handleSelect("marketing")}>
                    {intl.formatMessage({ id: "res.market" })}
                </div>
                <div id="news" className="resource-option-item mont-semi-bold-white-25px"
                    onClick={() => handleSelect("news")}>
                    {intl.formatMessage({ id: "res.social" })}
                </div>
                <div id="industry" className="resource-option-item mont-semi-bold-white-25px"
                    onClick={() => handleSelect("industry")}>
                    {intl.formatMessage({ id: "res.industry" })}
                </div>
                <div id="press" className="resource-option-item mont-semi-bold-white-25px"
                    onClick={() => handleSelect("press")}>
                    {intl.formatMessage({ id: "res.press" })}
                </div>
                <div className="resource-option-edge" />
            </div>
            {option === "marketing" &&
                <ResourceMarketing
                    pptData={[
                        "/Bluvec Technologies Full Solution.pdf",
                        "/Bluvec Technologies Smart Cities Solution.pdf",
                    ]}
                    webinarData={[
                        "https://www.linkedin.com/events/6975152060694421504/",
                        "https://www.youtube.com/watch?v=8Obn6G6bFHw",
                        "https://www.youtube.com/watch?v=kY3jL8y4Jwo",
                    ]}
                    FAQnum={25}
                />}
            {option === "news" && <ResourceLinkedin />}
            {option === "industry" &&
                <ResourceIndustry
                    industryEvents={[
                        "https://idexuae.ae/",
                        "https://www.smgconferences.com/defence/northamerica/conference/counter-uas-homeland-security",
                        "https://www.smgconferences.com/defence/uk/conference/counter-uas-europe",
                    ]}
                />}
            {option === "press" && <ResourcePress />}
            <Footer />
        </div>
    );
});

export { ResourcePage, ResourceIndustry, DownloadModal };